import dynamic from 'next/dynamic'
import {useEffect} from 'react'

const About = dynamic(() => import('@/components/about/About'))
const Work = dynamic(() => import('@/components/work/Work'))
const Contact = dynamic(() => import('@/components/contact/Contact'))

const OtherSections = ({
  aboutRef,
  workRef,
  contactRef,
  isPageFullyLoaded,
  setIsPageFullyLoaded,
}: {
  aboutRef: any
  workRef: any
  contactRef: any
  isPageFullyLoaded: any
  setIsPageFullyLoaded: any
}) => {
  useEffect(() => {
    setIsPageFullyLoaded(true)
  }, [setIsPageFullyLoaded])
  return (
    <>
      <About forwardedRef={aboutRef} />
      <Work
        forwardedRef={workRef}
        contactSection={contactRef}
        isPageFullyLoaded={isPageFullyLoaded}
      />
      <Contact forwardedRef={contactRef} />
    </>
  )
}

export default OtherSections
