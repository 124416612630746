'use client'
import Preloader from '@/components/preloader/Preloader'
import {ReactLenis, useLenis} from '@studio-freight/react-lenis'
import {AnimatePresence} from 'framer-motion'
import dynamic from 'next/dynamic'
import {useEffect, useRef, useState} from 'react'
import OtherSections from './OtherSections'
import useGridControls from './utils/useGridControls'

const Hero = dynamic(() => import('@/components/hero/Hero'))
const Navbar = dynamic(() => import('@/components/nav/Navbar'))

export default function Home() {
  const lenis = useLenis()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPageFullyLoaded, setIsPageFullyLoaded] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const heroRef = useRef<HTMLDivElement>(null)
  const aboutRef = useRef<HTMLDivElement>(null)
  const workRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)
  // const dom = useRef({
  //   hero: null,
  //   about: null,
  //   work: null,
  //   contact: null,
  // })

  const p1 = new Promise((resolve) => {
    setTimeout(resolve, 2000)
  })

  const p2 = new Promise((resolve) => {
    document.addEventListener('load', resolve, {once: true})
  })

  useEffect(() => {
    const preload = async () => {
      const ready = await Promise.all([p1, p2])
      setIsPageFullyLoaded(true)
      setIsLoading(false)
    }
    preload()
  })

  useEffect(() => {
    if (lenis) {
      const timer = setTimeout(() => {
        setIsLoading(false)
        document.body.style.cursor = 'default'
        lenis.scrollTo(0)
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [lenis])

  useGridControls()

  return (
    <ReactLenis root>
      <main className='no-scrollbar bg-white' ref={scrollContainerRef}>
        <AnimatePresence mode='wait'>{isLoading && <Preloader />}</AnimatePresence>
        <Hero heroRef={heroRef} />
        {!isLoading && (
          <>
            {isPageFullyLoaded && (
              <Navbar
                lenis={lenis}
                heroRef={heroRef}
                aboutRef={aboutRef}
                workRef={workRef}
                contactRef={contactRef}
              />
            )}
            <OtherSections
              aboutRef={aboutRef}
              workRef={workRef}
              contactRef={contactRef}
              isPageFullyLoaded={isPageFullyLoaded}
              setIsPageFullyLoaded={setIsPageFullyLoaded}
            />
          </>
        )}
      </main>
    </ReactLenis>
  )
}
